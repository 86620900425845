import { Role } from "@/models";
import { globals } from "@/utilities/globals";
import Router, { RouteConfig } from "vue-router";
import { roleNavigationGuard } from "./role-navigation-guard";

const routes: RouteConfig[] = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/services",
        name: "store-services",
        component: () => import("@/views/StoreServicesView.vue"),
        meta: { requiresAuth: true, role: Role.Admin }
    },
    {
        path: "/annual-meeting",
        name: "annual-meeting",
        component: () => import("@/views/AnnualMeeting.vue"),
        meta: { requiresAuth: true, role: Role.Admin }
    },
    {
        path: "/applications",
        name: "add-notification-application",
        component: () => import("@/views/NotificationApplicationsView.vue"),
        meta: { requiresAuth: true, role: Role.Admin }
    },
    {
        path: "/system-notifications",
        name: "add-system-notification",
        component: () => import("@/views/SystemNotificationsView.vue"),
        meta: { requiresAuth: true, role: Role.Admin }
    },
    {
        path: "/notification-events",
        name: "notification-event",
        component: () => import("@/views/NotificationEventsView.vue"),
        meta: { requiresAuth: true, role: Role.Admin }
    },
    {
        path: "/events/:id",
        name: "edit-notification-event",
        component: () => import("@/views/NewNotificationEventsView.vue"),
        meta: { requiresAuth: true, role: Role.Admin },
        props: true
    },
    {
        path: "/notification-event-roles",
        name: "add-notification-event-role",
        component: () => import("@/views/NotificationEventRolesView.vue"),
        meta: { requiresAuth: true, role: Role.Admin }
    },
    {
        path: "/admin-utilities",
        name: "admin-utilities",
        component: () => import("@/views/AdminUtilities.vue"),
        meta: { requiresAuth: true, role: Role.SystemAdmin }
    }
];

export const createRouter = () => {
    const router = new Router({
        mode: "history",
        base: globals.BASE_URL,
        routes
    });

    router.beforeEach(roleNavigationGuard);
    return router;
};
