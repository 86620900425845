

import { defineComponent, ref, toRefs } from "vue";
import { useOidcStore } from "@points/common";

import { Role } from "./models";
import { isInRole } from "./utilities";

export default defineComponent({
    name: "App",

    setup() {
        const { isAuthenticated } = toRefs(useOidcStore());
        const navigationItems = [
            {
                title: "Home",
                icon: "mdi-home",
                routeName: "home",
                role: Role.Admin
            },
            {
                title: "Store Services",
                icon: "mdi-wrench",
                routeName: "store-services",
                role: Role.Admin
            },
            {
                title: "Annual Meeting",
                icon: "mdi-airplane-takeoff",
                routeName: "annual-meeting",
                role: Role.Admin
            },
            {
                title: "System Notifications",
                icon: "new_releases",
                role: Role.Admin,
                children: [
                    {
                        title: "Applications",
                        icon: "apps",
                        routeName: "add-notification-application"
                    },
                    {
                        title: "Notifications",
                        icon: "add_alert",
                        routeName: "add-system-notification"
                    }
                ]
            },
            {
                title: "User Notifications",
                icon: "mdi-bell",
                role: Role.Admin,
                children: [
                    {
                        title: "Notification Events",
                        icon: "notification_important",
                        routeName: "notification-event"
                    },
                    {
                        title: "Event Roles",
                        icon: "person_add_alt_1",
                        routeName: "add-notification-event-role"
                    }
                ]
            },
            {
                title: "Admin Utilities",
                icon: "mdi-toolbox",
                routeName: "admin-utilities",
                role: Role.SystemAdmin
            }
        ];

        const drawer = ref(false);

        return {
            isInRole,
            isAuthenticated,
            navigationItems,
            drawer
        };
    }
});
