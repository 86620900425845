import { isInRole } from "@/utilities";
import { NavigationGuardNext, Route } from "vue-router";

export function roleNavigationGuard(to: Route, from: Route, next: NavigationGuardNext): void {
    if (to.matched.some((record) => (Array.isArray(record.meta.role) && typeof record.meta.role[0] === "string" || typeof record.meta.role === "string") && !isInRole(record.meta.role))) {
        next({ name: "forbidden" });
    } else {
        next();
    }
}
