import { useOidcStore } from "@points/common";

export function isInRole(role: string[] | string): boolean {
    const oidcStore = useOidcStore();

    if (typeof role === "string") {
        return oidcStore.roles.includes(role);
    } else {
        return role.some((r) => oidcStore.roles.includes(r));
    }
}
